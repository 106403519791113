
import { defineComponent } from 'vue';
import { AppWrapper } from '@/components';
import {
  QuoteForm
} from './_components';

export default defineComponent({
  name: 'Quote',
  components: {
    AppWrapper,
    QuoteForm
  }
});
